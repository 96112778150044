// change the theme
$theme-colors: (
  // "primary": #13BCCD,
  "primary": #05E2D8,
  "info": #3B3CCE,
  "secondary": #FF2277,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

