.bk-dog {
  background-image: url("../static/bkdog copy.jpg");
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  transition: opacity 0.5s;
  opacity: 1;
}

.home-background {
  background: #05e2d8ff;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.notification-badge {
  /* position: absolute; */
  /* top: -10px; */
  left: -20px;
  /* padding: 5px 10px; */
  /* border-radius: 50%; */
  background: red;
  color: white;
}

.counter.counter-lg {
  top: -24px !important;
}

.sec {
  position: relative;
  right: -13px;
  top: -22px;
}

.badge-notify {
  background: red;
  position: relative;
  top: -20px;
  left: -35px;
}

.w-13r {
  width: 13rem;
}
