html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Setting root text size across different screen sizes so elements will resize responsively */
@media (max-width: 370px) {
  html,
  body {
    font-size: 16px;
    /* below code removes unnecessary bouncing. It seems to work but will need to check this. */
    /* source: https://stackoverflow.com/questions/21209223/remove-bounce-on-scroll-in-browser-issue-with-positionfixed-div */
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }
}

/* trying to delineate between skinny and tall phones and short and stuby -- more work to do*/
@media (min-width: 370px) and (max-height: 800px) {
  html,
  body {
    font-size: 18px;
  }
}

@media (min-width: 370px) and (min-height: 800px) {
  html,
  body {
    font-size: 19px;
  }
}

@media (min-width: 600px) {
  html,
  body {
    font-size: 22px;
  }
}

/* below is for testing purposes, i will need to rethink / reconfigure later */
/* right now i think we should allow computers, but the experience will be the same as in mobile */
/* Note: when nav bar is "fixed" then it will not adjust based on changes to the body element, hence calling the navbar id */
@media (min-width: 800px) {
  html,
  body,
  #bottom-nav-generic {
    font-size: 20px;
    margin: auto;
    width: 700px;
  }
  html {
    background-color: lightgray;
  }
  body {
    min-height: 100vh
  }
}

/* @media (orientation: landscape) {
  html,
  body {
    font-size: 50px;
  }
} */